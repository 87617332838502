import { createAction, props } from '@ngrx/store';
import { AuthCredentials } from '@app/core/interfaces';
import { State } from './auth.reducer';
import {
  AuthorizationResponse,
  ModifiedUser,
  User
} from '@app/core/interfaces/user.model';
import { SaveUserProfile } from '@app/account/interfaces/saveUserProfile.interface';
import { AuthUser } from '@app/account/interfaces/authUser.interface';

export const AuthInitialized = createAction(
  '[Auth] AUTH_INITIALIZED',
  props<{ state: State }>()
);

export const AuthRefresh = createAction('[Auth] LOGIN_REFRESH_AUTH');

export const AuthRefreshSuccess = createAction(
  '[Auth] LOGIN_REFRESH_SUCESS',
  props<{ user: AuthUser }>()
);

export const AuthRefreshFailure = createAction(
  '[Auth] LOGIN_REFRESH_FAILURE',
  props<{ error: any }>()
);

export const LoginRequested = createAction(
  '[Auth] LOGIN_REQUESTED',
  props<{ authCredentials: AuthCredentials; onComplete: () => void }>()
);

export const LoginSuccess = createAction(
  '[Auth] LOGIN_SUCCESS',
  props<{ response: AuthorizationResponse }>()
);

export const LoginFailure = createAction(
  '[Auth] LOGIN_FAILURE',
  props<{ error: any }>()
);

export const LogoutRequested = createAction('[Auth] LOGOUT_REQUESTED');

export const LogoutSuccess = createAction('[Auth] LOGOUT_SUCCESS');

export const LogoutFailure = createAction(
  '[Auth] LOGOUT_FAILURE',
  props<{ error: any }>()
);

export const RegistrationRequested = createAction(
  '[Auth] REGISTER_REQUESTED',
  props<{ email: string }>()
);

export const RegistrationSuccess = createAction('[Auth] REGISTER_SUCCESS');

export const RegistrationFailure = createAction(
  '[Auth] REGISTER_FAILURE',
  props<{ error: any }>()
);

export const ResetPasswordRequested = createAction(
  '[Auth] RESET_PASSWORD_REQUESTED',
  props<{ email: string }>()
);

export const ResetPasswordSuccess = createAction(
  '[Auth] RESET_PASSWORD_SUCCESS'
);

export const ResetPasswordFailure = createAction(
  '[Auth] RESET_PASSWORD_FAILURE',
  props<{ error: any }>()
);

export const ForgotPasswordRequested = createAction(
  '[Auth] FORGOT_PASSWORD_REQUESTED',
  props<{ email: string }>()
);

export const ForgotPasswordSuccess = createAction(
  '[Auth] FORGOT_PASSWORD_SUCCESS'
);

export const ForgotPasswordFailure = createAction(
  '[Auth] FORGOT_PASSWORD_FAILURE',
  props<{ error: any }>()
);

export const UpdateUser = createAction(
  '[Auth] UPDATE_USER',
  props<{ user: ModifiedUser }>()
);

export const UpdateUserProfile = createAction(
  '[Auth] UPDATE_USER_PROFILE',
  props<{
    userId: string;
    userProfile: SaveUserProfile;
    onComplete: () => void;
  }>()
);

export const UpdateUserProfileSuccess = createAction(
  '[Auth] UPDATE_USER_PROFILE_SUCCESS',
  props<{ userProfile: SaveUserProfile }>()
);

export const UpdateUserProfileFailure = createAction(
  '[Auth] UPDATE_USER_PROFILE_FAILURE',
  props<{ error: any }>()
);

export const UpdateUserSuccess = createAction(
  '[Auth] UPDATE_USER_SUCCESS',
  props<{ user: User }>()
);

export const UpdateUserFailure = createAction(
  '[Auth] UPDATE_USER_FAILURE',
  props<{ error: any }>()
);

export const ClearError = createAction('[Auth] CLEAR_ERROR');
