import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-idle-modal',
  templateUrl: './idle-modal.component.html',
  styleUrls: ['./idle-modal.component.scss']
})
export class IdleModalComponent implements OnInit {
  @Input() modalTime: number;
  interval: any;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    clearInterval(this.interval);
    this.startCountdown();
  }

  get timedOut(): boolean {
    return this.modalTime === 0;
  }

  startCountdown() {
    this.interval = setInterval(() => {
      this.modalTime--;
      if (this.modalTime <= 0) {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  closeModal(): void {
    this.activeModal.close();
  }
}
