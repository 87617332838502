<svg 
  width="101" 
  height="58" 
  viewBox="0 0 101 58" 
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M48.45 0C50.87 0.36 53.51 0.95 54.88 2.48C56.25 4.01 56.69 8.38 55.44 10.49C55.07 11.11 54.36 12.19 53.6 12.44C52.68 12.75 51.71 12.24 51.01 11.99C51.06 11.62 51.39 11.38 51.61 11.09C52.24 10.27 52.75 9.27 53.04 8.05C53.62 5.62 53.25 2.58 51.54 1.62C50.63 1.11 49.44 0.97 48.34 0.53C48.34 0.39 48.34 0.25 48.34 0.12L48.45 0Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M32.14 12.9701C32.46 12.3101 33.12 11.8901 33.61 11.3201C34.05 10.8001 34.53 10.1601 34.81 9.44005C35.04 8.87005 35.12 8.17005 35.22 7.41005C35.32 6.70005 35.46 5.93005 35.45 5.19005C35.42 3.74005 34.86 2.36005 33.76 1.84005C33.08 1.53005 31.93 1.76005 31.32 1.31005C31.35 1.00005 31.6 0.930053 31.87 0.900053C33.71 0.680053 35.48 1.51005 36.46 2.59005C37.67 3.94005 37.93 5.97005 37.74 8.38005C37.66 9.41005 37.48 10.4301 37.1 11.3501C36.78 12.1301 36.28 13.0501 35.63 13.4601C34.63 14.0901 33.08 13.5101 32.14 12.9701Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M68.6 12.63C68.52 12.14 68.98 11.93 69.2 11.58C69.64 10.85 70.04 9.82999 70.33 8.83999C70.63 7.80999 70.9 6.54999 70.78 5.41999C70.7 4.64999 70.38 3.79999 70.07 3.27999C69.67 2.60999 68.98 2.29999 68.23 1.92999C67.87 1.74999 67.35 1.62999 67.18 1.13999C67.37 1.01999 68.04 1.19999 68.31 1.24999C70.96 1.77999 73.38 3.37999 73.57 6.46999C73.73 9.09999 73.09 11.55 71.69 12.63C71.49 12.79 71.17 13.04 70.9 13.12C70.03 13.36 69.25 12.89 68.6 12.63Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M46.04 6.83995C46.61 6.79995 46.78 7.34995 46.9 7.77995C47.29 9.04995 47.56 10.76 48.4 11.61C48.76 11.78 49.3 11.78 49.79 11.72C50.11 11.98 50.19 12.5 50.35 12.92C50.49 13.29 50.85 13.7 50.54 14.12C49.35 14.38 48.44 13.73 47.83 13.14C46.76 12.1 46.39 10.74 46.18 8.73995C46.12 8.13995 46.01 7.52995 45.95 6.93995L46.04 6.83995Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M63.67 7.25C64.64 7.31 64.68 8.46 64.84 9.43C65.01 10.53 65.39 12.15 66.31 12.51C66.56 12.61 66.97 12.54 67.18 12.62C67.7 12.82 68.16 14.04 68.08 14.73C67.48 15.22 66.65 14.84 66.2 14.47C65.84 14.17 65.44 13.64 65.07 13.12C64.74 12.66 64.35 12.16 64.09 11.65C63.49 10.46 63.39 8.83 63.56 7.37L63.67 7.25Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M31.16 12.29C31.32 12.62 31.26 13.09 31.31 13.57C31.36 14.07 31.7 14.68 31.01 14.73C30.45 14.77 29.7 14.1 29.39 13.79C28.94 13.33 28.64 12.89 28.38 12.25C27.8 10.83 27.87 9.19996 27.59 7.95996C27.55 7.77996 27.37 7.59996 27.4 7.39996C27.66 7.14996 28.17 7.83996 28.34 8.10996C28.54 8.42996 28.71 8.82996 28.83 9.15996C29.16 10.06 29.16 11.01 29.43 11.64C29.69 12.24 30.41 12.32 31.16 12.29Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M43 15.4499C44.92 17.3299 47.09 20.6699 46.87 24.6999C46.82 25.6299 46.5 26.7399 45.86 27.4099C44.83 28.4799 42.01 28.2899 40.6 27.6399C41.42 27.0899 42.14 26.6399 42.67 25.9099C43.22 25.1499 43.34 24.0899 43.23 22.7899C43.05 20.5099 42.25 18.6299 41.35 17.1499C40.25 18.4099 39.43 20.2799 38.79 22.3399C38.51 23.2299 38.23 24.3699 38.11 25.5299C37.99 26.6999 38.07 28.0599 38.52 28.9099C38.96 29.7399 40.8 30.9199 42.2 30.8299C42.63 30.7999 43.11 30.7299 43.48 30.4899C43.47 30.1099 43.07 30.0099 42.88 29.7799C42.61 29.4399 42.53 29.0099 42.32 28.5799C42.52 28.6099 42.76 28.5399 42.96 28.5799C43.75 28.7499 45.32 30.3799 45.48 31.6199C45.68 33.2299 44.41 33.9199 43.15 34.0599C41.58 34.2299 40.52 33.2899 39.65 32.5199C39.28 32.1899 38.94 31.8199 38.6 31.4699C38.26 31.1199 37.87 30.7699 37.62 30.3799C36.43 28.5099 36.04 24.9699 36.68 22.1099C37.3 19.3399 38.57 17.2799 40.02 15.6799C39.47 15.3799 38.67 14.8799 37.88 14.8199C37.24 14.7599 36.64 14.8599 36 14.6699C35.74 14.5899 35.26 14.4499 35.29 14.2599C35.33 13.9199 36.03 14.0999 36.38 14.1099C36.96 14.1199 37.61 13.9399 38.18 13.9999C39.25 14.0999 40.18 14.5399 41.11 14.7099C42.18 14.0699 43.3 13.2299 44.34 12.6799C44.56 12.5699 44.8 12.7399 44.83 13.0899C44.87 13.5699 44.43 14.1399 44.12 14.4799C43.75 14.8899 43.3 15.1499 43 15.4499Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M62.88 13.57C62.94 13.9 62.71 14.01 62.58 14.25C62.48 14.43 62.47 14.68 62.28 14.89C62.11 15.08 61.81 15.23 61.49 15.42C61.32 15.52 60.71 15.82 60.7 15.95C60.69 16.03 61.15 16.36 61.34 16.59C61.93 17.28 62.38 18.23 62.73 19.03C63.38 20.53 63.91 22.32 63.78 24.67C63.71 25.89 63.15 27.5 62.31 28.09C61.37 28.75 59.25 28.39 58.02 28.17C58.13 27.8 58.48 27.75 58.73 27.53C59.93 26.48 60.69 24.61 60.53 22.42C60.41 20.71 59.83 19.05 59.21 17.8C57.57 18.74 56.42 21.09 55.79 23.33C55.45 24.56 55.21 25.96 55.41 27.46C55.47 27.92 55.57 28.48 55.75 28.85C55.98 29.32 56.66 29.87 57.14 30.24C57.84 30.77 59.09 31.33 59.92 30.69C59.71 30.35 59.64 29.94 59.51 29.41C59.46 29.22 59.3 29.17 59.32 29C60.29 28.95 60.63 29.57 61.2 30.17C61.7 30.7 62.45 31.58 62.29 32.69C62.18 33.44 61.32 34.29 60.49 34.38C60.14 34.42 59.62 34.36 59.29 34.27C57.79 33.87 56.16 32.32 55.34 31.15C54.36 29.74 53.78 27.83 53.69 25.74C53.54 22.35 54.77 19.59 56.36 17.81C56.91 17.19 57.62 16.75 58.16 16.12C57.24 15.36 55.63 15.4 54.55 14.8C54.34 14.68 54.14 14.5 53.95 14.35C53.76 14.2 53.42 14.09 53.46 13.79C55.65 13.85 57.55 14.53 59.32 15.14C60.3 14.58 61.17 13.9 62.18 13.37C62.46 13.39 62.79 13.36 62.88 13.57Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M72.17 13.38C72.4 13.3 72.61 13.53 72.88 13.64C73.53 13.9 74.23 14.03 74.87 14.28C76.66 14.98 78.37 16.11 79.12 17.93C79.76 19.49 80.21 21.89 79.95 24.02C79.82 25.1 79.52 25.86 79.12 26.73C78.7 27.65 78.09 28.86 76.83 28.99C75.47 29.12 73.91 28.26 73.18 27.67C75.38 27.15 76.49 24.73 76.83 22.18C77.13 19.94 76.51 17.26 75.55 15.94C74.94 15.1 73.79 14.84 72.84 14.25C72.54 14.06 71.92 13.69 72.17 13.38Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M25.78 30.71C25.59 30.37 25.21 30.42 24.95 30.18C24.47 29.74 24.33 29.06 24.2 28.26C25.55 28.28 26.05 29.12 26.76 29.91C27.33 30.56 27.93 30.85 28.04 31.83C28.18 33.12 27.62 34.03 26.87 34.31C25.7 34.74 24.02 34.04 23.34 33.6C22.38 32.98 21.71 32.23 21.12 31.42C20.51 30.57 19.88 29.57 19.62 28.53C19.32 27.35 19.25 25.92 19.36 24.51C19.77 19.29 21.88 15.11 26.05 13.65C26.52 13.49 27 14.14 26.95 14.7C26.91 15.25 26.37 15.46 26.2 15.83C24.22 16.79 23.08 18.87 22.22 21.13C21.91 21.93 21.64 22.74 21.43 23.54C21.01 25.16 20.72 27.74 21.35 29.22C21.88 30.44 24.33 32.03 25.78 30.71Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M40.26 39.13C41.87 38.92 43.07 39.43 43.49 40.63C43.63 41.03 43.71 41.72 43.53 42.32C42.88 44.45 38.91 44.8 38.08 42.55C37.73 41.6 38.17 40.43 38.49 40.03C38.87 39.56 39.51 39.22 40.26 39.13ZM39.47 42.81C40 43.82 41.58 44.07 42.18 43C43 41.52 42.08 39.32 40.56 39.47C39.07 39.61 38.79 41.53 39.47 42.81Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M75.1 39.13C75.49 39.13 75.88 39.13 76.27 39.13C76.64 40.1 77.16 41.34 77.59 42.44C77.8 42.99 77.91 43.59 78.53 43.68V43.91C77.86 44.06 76.78 43.99 76.05 43.95V43.76C76.19 43.59 76.61 43.69 76.73 43.5C76.69 42.98 76.44 42.68 76.32 42.26C75.87 42.2 75.19 42.19 74.74 42.26C74.55 42.61 74.4 43 74.33 43.46C74.42 43.71 74.89 43.58 75.08 43.72V43.91C74.57 44.06 73.63 43.99 73.05 43.95C72.94 43.58 73.3 43.68 73.46 43.57C73.98 43.05 74.1 42.23 74.36 41.5C74.62 40.77 74.93 40.06 75.15 39.36C75.14 39.26 75.07 39.24 75.1 39.13ZM74.87 41.72C75.2 41.78 75.74 41.79 76.07 41.72C75.85 41.21 75.7 40.64 75.43 40.18C75.23 40.68 75.06 41.21 74.87 41.72Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M25.97 39.24V39.47C25.84 39.63 25.49 39.57 25.37 39.73C25.25 40.82 25.25 42.4 25.37 43.49C25.48 43.72 26.06 43.48 25.97 43.9C25.33 44.05 24.27 43.98 23.56 43.94C23.48 43.44 23.92 43.76 24.12 43.45C24.34 43.12 24.2 42.19 24.2 41.57C24.2 40.85 24.24 40.17 24.12 39.73C23.98 39.61 23.8 39.55 23.56 39.54C23.56 39.43 23.56 39.31 23.56 39.2C24.36 39.23 25.25 39.15 25.97 39.24Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M59.54 39.1999C59.89 39.1999 60.24 39.1999 60.59 39.1999C60.74 39.5599 60.29 39.6399 60.14 39.8399C60.01 39.9999 59.51 40.9399 59.5 41.1899C59.49 41.6499 60.1 42.2599 60.21 42.6899C60.41 42.3499 60.58 41.8199 60.81 41.2199C61.04 40.6499 61.72 39.6199 60.89 39.4899C60.89 39.3899 60.89 39.2899 60.89 39.1899C61.42 39.1899 61.94 39.1899 62.47 39.1899C62.61 39.6099 62.26 39.5199 62.06 39.6799C61.66 39.9899 61.35 41.2999 61.12 41.8599C60.79 42.6899 60.45 43.3899 60.22 44.0799C60.03 44.0799 59.84 44.0799 59.66 44.0799C59.37 43.5899 59.2 42.9899 58.87 42.5399C58.58 43.0099 58.36 43.5599 58.12 44.0799C57.92 44.0799 57.72 44.0799 57.52 44.0799C56.82 42.5799 56.15 41.0599 55.38 39.6499C55.24 39.5999 55.1 39.5499 54.93 39.5399C54.93 39.4299 54.93 39.3099 54.93 39.1999C55.61 39.1999 56.28 39.1999 56.96 39.1999C57.05 39.5399 56.82 39.5599 56.73 39.7299C57.1 40.8299 57.68 41.7399 58.12 42.7699C58.81 42.1499 58.48 41.6499 58.08 40.8199C57.9 40.4599 57.75 39.9899 57.55 39.7699C57.41 39.5999 57.09 39.5099 57.21 39.2099C57.74 39.2099 58.26 39.2099 58.79 39.2099V39.3999L58.9 39.5499L58.75 39.6299C58.79 40.0499 59.04 40.2699 59.16 40.6099C59.41 40.3699 59.57 40.0399 59.65 39.6299L59.42 39.5499L59.53 39.3999V39.1999H59.54Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.25 50.48C14.17 50.33 15.7 51.1 16.2 52.66C16.61 53.95 16.03 55.47 15.37 56.12C14.14 57.33 11.52 57.52 10.03 56.46C8.83999 55.61 8.31999 53.58 9.27999 52.06C9.75999 51.31 10.66 50.6 12.25 50.48ZM10.34 53.18C10.21 54.96 11.1 56.51 12.52 56.64C15.88 56.95 15.3 50.58 12.22 50.97C11.01 51.12 10.43 51.99 10.34 53.18Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M29.05 50.4799C31.05 50.3199 32.57 51.1199 33.03 52.6999C33.44 54.0999 32.87 55.5799 31.98 56.3099C30.1 57.8499 26.47 57.1999 25.74 54.9899C25.21 53.3799 25.99 51.8699 26.91 51.1899C27.51 50.7499 28.16 50.5499 29.05 50.4799ZM28.57 56.4199C29.09 56.6899 29.78 56.7999 30.45 56.4599C32.62 55.3699 31.62 50.6999 29.06 50.9699C26.49 51.2399 26.7 55.4499 28.57 56.4199Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M80.51 50.48C82.79 50.3 84.45 51.38 84.61 53.37C84.81 55.95 82.18 57.93 79.27 56.94C77.93 56.49 76.52 54.91 77.2 52.81C77.63 51.49 78.86 50.61 80.51 50.48ZM81.3 56.64C83.25 56.38 83.47 53.46 82.5 51.94C82.09 51.3 81.4 50.87 80.51 50.96C77.54 51.29 78.25 57.05 81.3 56.64Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M38.71 53.97C38.98 54.36 39.31 54.8 39.65 55.25C40.14 55.9 40.61 56.54 41.64 56.64C41.64 56.79 41.64 56.94 41.64 57.09C40.33 57.08 39.52 57.14 38.86 56.6C38.54 56.33 38.25 55.84 37.96 55.43C37.56 54.88 37.19 54.12 36.27 54.34C36.19 55.36 35.98 56.83 37.32 56.56C37.32 56.7 37.32 56.84 37.32 56.97C36.13 56.97 34.94 56.97 33.75 56.97C33.75 56.83 33.75 56.69 33.75 56.56C35.13 56.72 34.8 55.27 34.8 53.82C34.8 52.4 35.16 50.9 33.78 51.08C33.78 50.93 33.78 50.78 33.78 50.63C35.85 50.6 39.48 49.88 40.02 51.83C40.35 52.99 39.64 53.7 38.71 53.97ZM38.38 53.15C39.05 51.9 37.8 50.61 36.28 51.05C36.22 51.89 36.22 52.99 36.28 53.83C37.16 53.88 38.06 53.72 38.38 53.15Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M66.6 50.48C66.69 51.09 66.74 51.74 66.79 52.4C66.64 52.4 66.49 52.4 66.34 52.4C66.39 51.22 65.39 51.05 64.12 51.23C64.05 51.91 64.08 52.84 64.08 53.9C64.08 54.66 63.88 56.03 64.27 56.38C64.53 56.62 64.89 56.46 65.21 56.61C65.21 56.73 65.21 56.86 65.21 56.99C63.98 56.99 62.75 56.99 61.53 56.99C61.53 56.88 61.53 56.76 61.53 56.65C61.82 56.45 62.15 56.64 62.43 56.42C62.86 56.1 62.66 54.68 62.66 53.9C62.66 52.83 62.69 51.92 62.62 51.23C61.99 51.23 61.29 51.06 60.85 51.31C60.48 51.51 60.53 51.95 60.4 52.4C60.25 52.4 60.1 52.4 59.95 52.4C59.97 51.77 60.07 51.22 60.1 50.6L60.18 50.49C60.4 50.4 60.6 50.6 60.86 50.64C61.48 50.74 62.47 50.64 63.38 50.64C64.28 50.64 65.21 50.73 65.86 50.64C66.11 50.59 66.33 50.4 66.6 50.48Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M90.17 53.97C90.44 54.35 90.77 54.79 91.11 55.25C91.58 55.88 92.06 56.49 92.99 56.64C93.61 56.55 94.05 56.45 94.12 55.66C94.2 54.73 94.19 52.9 94.12 51.98C94.07 51.28 93.86 51.04 93.14 51.08C93.14 50.93 93.14 50.78 93.14 50.63C94.24 50.63 95.35 50.63 96.45 50.63C96.45 50.78 96.45 50.93 96.45 51.08C95.1 50.86 95.58 52.66 95.55 53.64C96.1 53.57 96.47 53.23 96.94 52.74C97.32 52.34 98.5 51.03 97.09 51.09C97.09 50.94 97.09 50.79 97.09 50.64C98.01 50.64 98.92 50.64 99.83 50.64C99.83 50.79 99.83 50.94 99.83 51.09C98.23 51.15 98.14 52.73 97.16 53.42C98.44 54.32 98.75 56.19 100.54 56.58C100.54 56.72 100.54 56.86 100.54 56.99C99.39 56.99 98.23 56.99 97.08 56.99C97.08 56.88 97.08 56.76 97.08 56.65C97.17 56.44 97.62 56.59 97.72 56.39C97.57 55.86 97.16 55.44 96.82 55.07C96.48 54.7 96.14 54.21 95.58 54.09C95.48 55.01 95.19 56.75 96.44 56.57C96.44 56.71 96.44 56.85 96.44 56.98C95.37 56.98 94.31 56.98 93.24 56.98C93.07 56.93 93.14 57.11 93.01 57.09C90.13 57.54 89.7 55.54 88.46 54.35C88.26 54.3 87.91 54.28 87.71 54.35C87.63 55.37 87.43 56.83 88.76 56.57C88.76 56.71 88.76 56.85 88.76 56.98C87.57 56.98 86.38 56.98 85.19 56.98C85.19 56.84 85.19 56.7 85.19 56.57C85.72 56.53 86.15 56.52 86.24 55.93C86.33 55.32 86.24 54.57 86.24 53.64C86.24 52.29 86.57 50.91 85.22 51.08C85.22 50.93 85.22 50.78 85.22 50.63C87.14 50.62 89.93 50.04 91.05 51.16C91.93 52.02 91.49 53.84 90.17 53.97ZM89.38 53.6C90.22 53.12 90.15 51.68 89.38 51.23C88.98 50.99 88.52 50.98 87.84 50.97L87.73 51.05C87.67 51.89 87.67 52.99 87.73 53.83C88.38 53.82 88.91 53.86 89.38 53.6Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.149994 56.9401C-0.0600065 56.3401 0.689994 56.6301 0.939994 56.3401C1.18999 56.0501 1.08999 54.9501 1.08999 54.1601C1.08999 53.2001 1.14999 52.4101 1.08999 51.8701C1.02999 51.2901 0.739994 51.0701 0.149994 51.0401C0.149994 50.9301 0.149994 50.8101 0.149994 50.7001C1.50999 50.5201 3.44999 50.3801 4.95999 50.5901C6.71999 50.8301 7.96999 51.9701 7.73999 54.1601C7.64999 55.0601 7.11999 55.7901 6.57999 56.2301C5.20999 57.3201 2.62999 56.9301 0.149994 56.9401ZM2.51999 53.7501C2.51999 54.4101 2.30999 56.0301 2.62999 56.3801C2.81999 56.5901 3.35999 56.5601 3.71999 56.5301C5.26999 56.4001 6.26999 55.2701 6.23999 53.7501C6.19999 51.7001 4.76999 50.7901 2.55999 51.0801C2.48999 51.8901 2.51999 52.7701 2.51999 53.7501Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M23.3 54.88C23.12 53.79 24.05 50.62 22.25 51.08C22.25 50.93 22.25 50.78 22.25 50.63C23.13 50.63 24 50.63 24.88 50.63C24.88 50.78 24.88 50.93 24.88 51.08C24.65 51.12 24.23 51.03 24.05 51.27C23.88 51.49 23.98 52.21 23.98 52.66C23.98 54.07 23.98 55.8 23.98 57.02C23.84 56.98 23.6 57.07 23.42 57.02C23.05 56.92 22.49 56.1 22.18 55.78C20.96 54.54 19.74 53.22 18.57 52.02C18.57 52.87 18.44 54.74 18.53 55.7C18.59 56.31 18.86 56.56 19.54 56.56C19.54 56.7 19.54 56.84 19.54 56.97C18.71 56.97 17.89 56.97 17.06 56.97C17.06 56.86 17.06 56.74 17.06 56.63C17.24 56.41 17.63 56.57 17.81 56.29C18.08 55.87 17.89 54.58 17.89 53.81C17.89 52.82 17.92 51.94 17.85 51.29C17.69 51.12 17.41 51.08 17.1 51.06C17.12 50.92 17.05 50.69 17.14 50.61C17.86 50.75 18.49 50.42 19.17 50.61C19.49 50.7 19.95 51.39 20.22 51.66C21.27 52.73 22.32 53.87 23.3 54.88Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M51.31 54.84C51.12 53.76 52.08 50.67 50.29 51.08C50.29 50.93 50.29 50.78 50.29 50.63C51.17 50.63 52.04 50.63 52.92 50.63C52.92 50.78 52.92 50.93 52.92 51.08C51.9 50.88 51.98 51.68 51.98 52.7C51.98 54.08 51.98 55.82 51.98 57.02C51.31 57.15 51.17 56.81 50.78 56.42C49.37 55.01 47.99 53.4 46.61 52.06C46.54 52.58 46.57 53.38 46.57 54.16C46.57 55.45 46.31 56.69 47.58 56.57C47.58 56.71 47.58 56.85 47.58 56.98C46.75 56.98 45.93 56.98 45.1 56.98C45.1 56.87 45.1 56.75 45.1 56.64C45.26 56.42 45.57 56.59 45.78 56.38C46.12 56.03 45.93 54.66 45.93 53.9C45.93 52.88 45.96 51.96 45.89 51.31C45.7 51.17 45.43 51.1 45.1 51.08C45.12 50.94 45.05 50.71 45.14 50.63C45.87 50.78 46.53 50.43 47.21 50.63C47.49 50.72 48.02 51.41 48.26 51.68C49.25 52.76 50.35 53.85 51.31 54.84Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M58.94 50.63C58.94 51.15 59.03 51.59 59.02 52.13C58.91 52.13 58.79 52.13 58.68 52.13C58.45 51.83 58.58 51.42 58.19 51.26C57.58 51.02 56.45 51.33 55.75 51.26C55.69 51.89 55.69 52.77 55.75 53.4C56.59 53.51 58.19 53.73 58.16 52.72C58.29 52.72 58.41 52.72 58.54 52.72C58.54 53.41 58.54 54.1 58.54 54.79C58.43 54.79 58.31 54.79 58.2 54.79C57.99 54.58 58.13 54.3 57.86 54.15C57.39 53.88 56.39 54.1 55.79 54.07C55.66 54.66 55.66 55.75 55.83 56.29C56.59 56.3 57.86 56.6 58.46 56.21C58.81 55.99 58.75 55.68 58.99 55.35C59.12 55.35 59.24 55.35 59.37 55.35C59.33 55.89 59.28 56.41 59.26 56.97C57.21 56.97 55.15 56.97 53.1 56.97C53.1 56.83 53.1 56.69 53.1 56.56C53.45 56.56 54.06 56.55 54.23 56.26C54.47 55.84 54.31 54.6 54.31 53.82C54.31 52.47 54.71 50.88 53.33 51.08C53.35 50.94 53.28 50.71 53.37 50.63C55.2 50.63 57.07 50.63 58.94 50.63Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M69.84 50.63C69.84 50.75 69.84 50.88 69.84 51.01C69.79 51.16 69.53 51.1 69.5 51.27C70.01 52.8 70.8 54.05 71.42 55.48C71.56 55.09 72 54.76 72.02 54.31C72.04 53.92 71.58 53.22 71.34 52.73C71 52.03 70.78 51.27 70.17 51.04C70.17 50.9 70.17 50.76 70.17 50.63C71.66 50.63 73.15 50.63 74.64 50.63C74.64 50.78 74.64 50.93 74.64 51.08C73.75 51.4 73.68 52.55 73.17 53.26C73.46 53.99 73.82 54.64 74.15 55.33C74.51 54.58 74.97 53.42 75.35 52.44C75.56 51.89 75.97 51.07 75.09 51.09C75.09 50.94 75.09 50.79 75.09 50.64C75.78 50.64 76.47 50.64 77.16 50.64C77.16 50.79 77.16 50.94 77.16 51.09C77 51.11 76.87 51.15 76.75 51.2C76.28 51.56 76.08 52.28 75.81 52.97C75.28 54.31 74.69 55.8 74.16 57.14C73.91 57.14 73.66 57.14 73.41 57.14C73.12 56.4 72.73 55.77 72.43 55.03C71.98 55.63 71.72 56.42 71.38 57.14C71.13 57.14 70.88 57.14 70.63 57.14C69.96 55.77 69.17 54.11 68.45 52.55C68.19 52 67.95 51.03 67.13 51.08C67.13 50.93 67.13 50.78 67.13 50.63C68.03 50.63 68.93 50.63 69.84 50.63ZM72.2 51.19C72.33 51.73 72.59 52.13 72.84 52.54C73.03 52.07 73.34 51.74 73.44 51.19C73.2 51.02 72.45 51.02 72.2 51.19Z" fill="white"/>
  <path d="M58.81 39.56C58.93 39.55 59.05 39.54 59.17 39.54C59.29 39.54 59.41 39.55 59.53 39.56C59.62 39.57 59.71 39.51 59.72 39.41C59.73 39.32 59.67 39.23 59.57 39.22C59.44 39.2 59.31 39.2 59.17 39.2C59.04 39.2 58.9 39.21 58.77 39.22C58.68 39.23 58.61 39.32 58.62 39.41C58.63 39.5 58.71 39.57 58.81 39.56Z" fill="white"/>
  <path d="M0.09 46.91C33.33 46.91 66.57 46.91 99.81 46.91L99.9 46.82L99.81 46.73C66.57 46.73 33.33 46.73 0.09 46.73L0 46.81L0.09 46.91Z" fill="white"/>
</svg>
