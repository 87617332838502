/* tslint:disable:directive-selector */
import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectUserRole } from '../../core/store/auth/auth.selectors';

@Directive({
  selector: '[hasRole]'
})
export class HasRoleDirective implements OnInit, OnDestroy {
  private userRole: string;
  private roles: string[] = [];
  private isHidden = true;
  private ngDestroy$ = new Subject();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store
  ) {}

  ngOnInit() {
    this.store
      .select(selectUserRole)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(role => {
        this.userRole = role;
        this.updateView();
      });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
  }

  @Input()
  set hasRole(roles: string[]) {
    this.roles = roles;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    if (this.userRole)
      return this.roles.some(
        x => x.toLowerCase() === this.userRole.toLowerCase()
      );
    else return false;
  }
}
