import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromDonor from './donor.reducer';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromDonor.donorFeatureKey,
      fromDonor.reducer
    )
  ],
})
export class DonorStoreModule {}
