import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core/services';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IdleModalComponent } from './core/idle-modal/idle-modal.component';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { selectIsAuthenticated } from './core/store/auth/auth.selectors';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'First Call';
  private modalRef: any;
  private modalTime = 10;
  private idleTime = 1500;
  private ngDestroyed$ = new Subject();

  constructor(
    private idle: Idle,
    private authService: AuthenticationService,
    private store: Store,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit() {
    this.configureIdle();

    this.store
      .select(selectIsAuthenticated)
      .pipe(
        takeUntil(this.ngDestroyed$),
        tap(userIsAuthenticated => {
          if (userIsAuthenticated) {
            this.idle.watch();
          } else {
            this.idle.stop();
          }
        })
      )
      .subscribe();
  }

  configureIdle(): void {
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.setIdle(this.idleTime);
    this.idle.setTimeout(this.modalTime);

    this.idle.onIdleEnd.pipe(takeUntil(this.ngDestroyed$)).subscribe(() => {
      if (this.modalRef) {
        this.hideModal();
      }

      this.idle.watch();
    });

    this.idle.onTimeout.pipe(takeUntil(this.ngDestroyed$)).subscribe(() => {
      this.logOut();
    });

    this.idle.onIdleStart.pipe(takeUntil(this.ngDestroyed$)).subscribe(() => {
      this.showModal();
    });
  }

  showModal() {
    this.modalRef = this.modalService.open(IdleModalComponent, {
      centered: true
    });
    this.modalRef.componentInstance.modalTime = this.modalTime;
  }

  hideModal() {
    this.modalRef.close();
  }

  logOut() {
    this.authService.logOut(this.router.routerState.snapshot.url);
  }

  ngOnDestroy() {
    this.ngDestroyed$.next(null);
    this.ngDestroyed$.complete();
  }
}
