import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'normalized' })
/**
 * Replace title case with normalized text. Ex 'TitleCase' returns 'Title Case'
 */
export class NormalizedPipe implements PipeTransform {
  transform(value: string) {
    return value.replace(/([A-Z])/g, ' $1').trim();
  }
}
