import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Route,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { AuthenticationService } from '../services';
import { Store } from '@ngrx/store';
import { selectIsAuthenticated } from '../store/auth/auth.selectors';
import { AppState } from '../store/app.state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// https://angular.io/api/router/CanActivate
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private store: Store<AppState>
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(route, state);
  }

  checkLogin(url: string): Observable<boolean> {
    return this.store.select(selectIsAuthenticated).pipe(
      map(isLoggedIn => {
        if (isLoggedIn) {
          return true;
        }

        // Store the attempted URL for redirecting
        // this.authService.redirectUrl = url;

        // Redirect to the login page
        this.router.navigate(['/account/login']);

        return false;
      })
    );
  }

  canLoad(route: Route): Observable<boolean> {
    const url = `/${route.path}`;

    return this.checkLogin(url);
  }
}
