import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { InputRestrictionDirective } from './directives/special-char-input.directive';
import { WholeNumbersOnlyDirective } from './directives/whole-numbers-only.directive';
import { DecimalNumbersDirective } from './directives/decimal-numbers.directive';
import { CustomDatePipe } from './pipes/customDate.pipe';
import { TimeAgoPipe } from './pipes/timeAgo.pipe';
import { HasRoleDirective } from './directives/hasRole.directive';
import { NormalizedPipe } from './pipes/normalized.pipe';
import { AppLogoComponent } from './components/logo/logo.component';
import { AppSpinnerComponent } from './components/spinner/spinner.component';
import { AppBreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { AppPageTitleComponent } from './components/page-title/page-title.component';

@NgModule({
  declarations: [
    AppPageTitleComponent,
    AppBreadcrumbsComponent,
    AppSpinnerComponent,
    AppLogoComponent,
    PhoneMaskDirective,
    InputRestrictionDirective,
    WholeNumbersOnlyDirective,
    DecimalNumbersDirective,
    CustomDatePipe,
    TimeAgoPipe,
    HasRoleDirective,
    NormalizedPipe
  ],
  exports: [
    AppPageTitleComponent,
    AppBreadcrumbsComponent,
    AppSpinnerComponent,
    AppLogoComponent,
    PhoneMaskDirective,
    InputRestrictionDirective,
    WholeNumbersOnlyDirective,
    DecimalNumbersDirective,
    CustomDatePipe,
    TimeAgoPipe,
    HasRoleDirective,
    NormalizedPipe
  ],
  imports: [CommonModule],
  providers: [CustomDatePipe, TimeAgoPipe]
})
export class SharedModule {}
