import { Component } from '@angular/core';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class AppPageTitleComponent {
  constructor(
  ) {}
}
