import { createReducer, on, Action } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { AuthUser } from '@app/account/interfaces/authUser.interface';

export const authFeatureKey = 'auth';

export interface State {
  isAuthenticated: boolean;
  loading: boolean;
  user?: AuthUser;
  error: string;
}

export const initialState: State = {
  isAuthenticated: false,
  loading: false,
  user: undefined,
  error: ''
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.AuthInitialized, (state, { state: authState }) => ({
    ...state,
    ...authState,
    loading: false
  })),
  on(AuthActions.AuthRefresh, (state, action) => ({
    ...state
  })),
  on(AuthActions.AuthRefreshSuccess, (state, action) => ({
    ...state,
    loading: false,
    isAuthenticated: true,
    user: action.user
  })),
  on(AuthActions.AuthRefreshFailure, (state, action) => ({
    ...state,
    loading: false,
    isAuthenticated: false
  })),
  on(AuthActions.AuthInitialized, (state, { state: authState }) => ({
    ...state,
    ...authState,
    loading: false
  })),
  on(AuthActions.LoginRequested, (state, action) => ({
    ...state,
    loading: true
  })),
  on(AuthActions.LoginSuccess, (state, action) => ({
    ...state,
    loading: false,
    isAuthenticated: true,
    user: action.response.user,
    error: ''
  })),
  on(AuthActions.LoginFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.error.message
  })),
  on(AuthActions.LogoutRequested, (state, action) => ({
    ...state,
    loading: true
  })),
  on(AuthActions.LogoutSuccess, (state, action) => ({
    ...state,
    isAuthenticated: false,
    user: undefined,
    loading: false
  })),
  on(AuthActions.LogoutFailure, (state, action) => ({
    ...state,
    loading: false
  })),
  on(AuthActions.RegistrationRequested, (state, action) => ({
    ...state,
    loading: true
  })),
  on(AuthActions.RegistrationSuccess, (state, action) => ({
    ...state,
    loading: false
  })),
  on(AuthActions.RegistrationFailure, (state, action) => ({
    ...state,
    loading: false
  })),
  on(AuthActions.ResetPasswordRequested, (state, action) => ({
    ...state,
    loading: true
  })),
  on(AuthActions.ResetPasswordSuccess, (state, action) => ({
    ...state,
    loading: false
  })),
  on(AuthActions.ResetPasswordFailure, (state, action) => ({
    ...state,
    loading: false
  })),
  on(AuthActions.UpdateUserProfileSuccess, (state, { userProfile }) => ({
    ...state,
    loading: false,
    user: {
      ...state.user,
      firstName: userProfile.firstName,
      lastName: userProfile.lastName
    }
  })),
  on(AuthActions.ClearError, state => ({
    ...state,
    loading: false,
    error: ''
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}
