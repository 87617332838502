import { AppState } from '../app.state';
import { State } from './auth.reducer';
import { createSelector } from '@ngrx/store';
import { AuthUser } from '@app/account/interfaces/authUser.interface';

export const selectAuthState = (state: AppState): State => {
  return state.auth;
};

export const selectIsAuthenticated = createSelector(
  selectAuthState,
  (authState: State) => {
    return authState.isAuthenticated;
  }
);

export const selectUser = createSelector(
  selectAuthState,
  (authState: State): AuthUser => authState.user
);

export const selectUserRole = createSelector(
  selectUser,
  (user: AuthUser): string => user?.role
);

export const selectLoadingState = createSelector(
  selectAuthState,
  (authState: State): boolean => authState.loading
);

export const selectError = createSelector(
  selectAuthState,
  (authState: State): string => authState.error
);
