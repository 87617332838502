import { Injectable } from '@angular/core';
import { AuthUser } from '@app/account/interfaces/authUser.interface';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@env/environment';

@Injectable()
export class TokenService {
  private readonly userKey = 'user';
  private readonly accessTokenKey = 'accessToken';
  private readonly refreshTokenKey = 'refreshToken';
  private readonly jwtHelperService: JwtHelperService;

  constructor(private cookieService: CookieService) {
    this.jwtHelperService = new JwtHelperService();
  }

  get storage() {
    return sessionStorage;
  }

  clearStorage() {
    this.storage.removeItem(this.userKey);
    this.storage.removeItem(this.accessTokenKey);
    this.storage.removeItem(this.refreshTokenKey);
  }

  saveTokens(accessToken: string, refreshToken: string): void {
    this.saveAccessToken(accessToken);
    this.saveRefreshToken(refreshToken);
    this.cookieService.set('access_token', accessToken, {
      domain: environment.cookieDomain
    });
  }

  saveAccessToken(accessToken: string): void {
    this.storage.setItem(this.accessTokenKey, accessToken);
  }

  getAccessToken(): string {
    return this.storage.getItem(this.accessTokenKey);
  }

  saveRefreshToken(refreshToken: string): void {
    this.storage.setItem(this.refreshTokenKey, JSON.stringify(refreshToken));
  }

  getRefreshToken(): string {
    return JSON.parse(this.storage.getItem(this.refreshTokenKey));
  }

  saveUser(user: AuthUser): void {
    this.storage.setItem(this.userKey, JSON.stringify(user));
  }

  getUser(): AuthUser {
    return JSON.parse(this.storage.getItem(this.userKey));
  }

  hasTokenExpired(): boolean {
    const accessToken = this.getAccessToken();

    return this.jwtHelperService.isTokenExpired(accessToken);
  }
}
