import { createAction, props } from '@ngrx/store';
import { DonorDetails } from '../interfaces/donorDetails.interface';
import { TimelineDetails } from '../interfaces/timelineDetails.interface';

export const UpdateSelectedDonor = createAction(
  "[Donor] Update Selected Donor Details",
  props<{ donorDetails: DonorDetails }>()
);

export const UpdateDonorTimeline = createAction(
  "[Donor] Update Selected Donor Timeline",
  props<{ timeline: TimelineDetails }>()
)
