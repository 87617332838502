import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of, defer } from 'rxjs';
import { AuthenticationService } from '@app/core/services';
import { initialState } from './auth.reducer';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import * as AuthActions from './auth.actions';
import { Router } from '@angular/router';
import { ToastService } from '@app/core/services/toast.service';
import { TokenService } from '@app/core/services/token.service';
import { Response } from '@app/core/interfaces/response.interface';
import { AuthorizationResponse } from '@app/core/interfaces/user.model';
import { AccountService } from '@app/account/services/account.service';
import { UserRoles } from '@app/core/constants/userRoles.constants';

@Injectable()
export class AuthEffects {
  constructor(
    private action$: Actions,
    private authenticationService: AuthenticationService,
    private accountService: AccountService,
    private tokenService: TokenService,
    private toastService: ToastService,
    private router: Router
  ) {}

  login$ = createEffect(() =>
    this.action$.pipe(
      ofType(AuthActions.LoginRequested),
      switchMap(action =>
        this.authenticationService.login$(action.authCredentials).pipe(
          finalize(() => action.onComplete()),
          map((response: AuthorizationResponse) =>
            response.succeeded
              ? AuthActions.LoginSuccess({ response })
              : AuthActions.LoginFailure({
                  error: Error(response.errorMessage)
                })
          ),
          catchError((error: any) => of(AuthActions.LoginFailure({ error })))
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(AuthActions.LoginSuccess),
        tap(action => {
          this.tokenService.saveUser(action.response.user);
          const defaultRoute =
            action.response.user.role !== UserRoles.ReferralUser
              ? 'dashboard'
              : 'referral/form';
          const redirectTo =
            this.authenticationService.redirectUrl || defaultRoute;
          this.router.navigate([redirectTo]);
        })
      ),
    {
      dispatch: false
    }
  );

  forgotPassword$ = createEffect(() =>
    this.action$.pipe(
      ofType(AuthActions.ForgotPasswordRequested),
      switchMap(action =>
        this.authenticationService.forgotPassword$(action.email).pipe(
          map((response: Response) =>
            response.succeeded
              ? AuthActions.ForgotPasswordSuccess()
              : AuthActions.ForgotPasswordFailure({
                  error: Error(response.errorMessage)
                })
          ),
          catchError((error: any) =>
            of(AuthActions.ForgotPasswordFailure({ error }))
          )
        )
      )
    )
  );

  resetPassword$ = createEffect(() =>
    this.action$.pipe(
      ofType(AuthActions.ResetPasswordRequested),
      switchMap(action =>
        this.authenticationService.forgotPassword$(action.email).pipe(
          map((response: Response) =>
            response.succeeded
              ? AuthActions.ResetPasswordSuccess()
              : AuthActions.ResetPasswordFailure({
                  error: Error(response.errorMessage)
                })
          ),
          catchError((error: any) =>
            of(AuthActions.ForgotPasswordFailure({ error }))
          )
        )
      )
    )
  );

  resetPasswordSuccess$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(AuthActions.ResetPasswordSuccess),
        tap(action => {
          this.toastService.showSuccessToast('Email sent!');
        })
      ),
    {
      dispatch: false
    }
  );

  forgotPasswordSuccess$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(AuthActions.ForgotPasswordSuccess),
        tap(action => {
          this.toastService.showSuccessToast('Email sent!');
          this.router.navigate(['/account/login']);
        })
      ),
    {
      dispatch: false
    }
  );

  authFailure$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(
          AuthActions.LoginFailure,
          AuthActions.ForgotPasswordFailure,
          AuthActions.ResetPasswordFailure
        ),
        tap(action => {
          this.toastService.showErrorToast(action.error);
        })
      ),
    {
      dispatch: false
    }
  );

  logout$ = createEffect(() =>
    this.action$.pipe(
      ofType(AuthActions.LogoutRequested),
      tap(() => {
        this.tokenService.clearStorage();
        this.router.navigate(['/account/login']);
      }),
      map(action => AuthActions.LogoutSuccess())
    )
  );

  updateUser$ = createEffect(() =>
    this.action$.pipe(
      ofType(AuthActions.UpdateUser),
      switchMap(action =>
        this.authenticationService.updateUser$(action.user).pipe(
          map(user => {
            if (!user) {
              this.toastService.showErrorToast(
                'Something went wrong. Please submit again'
              );
              return AuthActions.UpdateUserFailure({ error: new Error() });
            } else {
              this.toastService.showSuccessToast('Update Successful!');
              return AuthActions.UpdateUserSuccess({ user });
            }
          }),
          catchError(error => of(AuthActions.UpdateUserFailure({ error })))
        )
      )
    )
  );

  updateUserProfile$ = createEffect(() =>
    this.action$.pipe(
      ofType(AuthActions.UpdateUserProfile),
      switchMap(action =>
        this.accountService
          .updateUserProfile$(action.userId, action.userProfile)
          .pipe(
            finalize(() => action.onComplete()),
            map(response => {
              this.toastService.showSuccessToast('Update Successful!');
              return AuthActions.UpdateUserProfileSuccess({
                userProfile: action.userProfile
              });
            }),
            catchError(error => {
              this.toastService.showErrorToast(
                'Something went wrong. Please submit again'
              );
              return of(AuthActions.UpdateUserProfileFailure({ error }));
            })
          )
      )
    )
  );

  authInitialized$ = createEffect(() =>
    this.action$.pipe(
      ofType(AuthActions.AuthRefresh),
      switchMap(() => {
        const accessToken = this.tokenService.getAccessToken();
        const refreshToken = this.tokenService.getRefreshToken();
        return this.authenticationService
          .refresh$({ accessToken, refreshToken })
          .pipe(
            map(() => {
              const user = this.tokenService.getUser();
              const defaultRoute =
                user.role !== UserRoles.ReferralUser
                  ? 'dashboard'
                  : 'referral/form';
              const redirectTo =
                this.authenticationService.redirectUrl || defaultRoute;
              this.router.navigate([redirectTo]);
              return AuthActions.AuthRefreshSuccess({ user });
            }),
            catchError((error: any) =>
              of(AuthActions.AuthRefreshFailure({ error }))
            )
          );
      })
    )
  );

  init$ = createEffect(() =>
    defer(() => {
      const accessToken = this.tokenService.getAccessToken();
      const hasTokenExpired = this.tokenService.hasTokenExpired();
      const isAuthenticated = !!accessToken && !hasTokenExpired;
      const user = this.tokenService.getUser();

      if (user && accessToken && hasTokenExpired)
        return of(AuthActions.AuthRefresh());

      return of(
        AuthActions.AuthInitialized({
          state: {
            ...initialState,
            isAuthenticated,
            ...(isAuthenticated
              ? {
                  user: this.tokenService.getUser()
                }
              : {})
          }
        })
      );
    })
  );
}
