<div class="modal-header light-blue-backdrop">
  <button
    *ngIf="timedOut"
    type="button"
    class="close"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <app-page-title>
    <h1>Are you still there?</h1>
  </app-page-title>
  <p *ngIf="timedOut" class="mb-4">You were logged out due to inactivity.</p>
  <p *ngIf="!timedOut" class="mb-4">
    You will be automatically logged out in
    <span class="danger">{{ modalTime }}</span>
    seconds!
  </p>
</div>
<div *ngIf="timedOut" class="modal-footer">
  <button type="button" class="btn btn-submit" (click)="closeModal()">
    Close
  </button>
</div>
