import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'customDate'})
export class CustomDatePipe implements PipeTransform {
  transform(value: string) {
    const dateString = new Date(value).toLocaleDateString();
    const timeString = new Date(value).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    const formatted = dateString + ' at ' + timeString;
    return formatted;
  }
}