import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '../interfaces/response.interface';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthCredentials } from '@app/core/interfaces';
import { LogoutRequested } from '@app/core/store/auth/auth.actions';
import {
  AuthorizationResponse,
  ModifiedUser,
  User
} from '@app/core/interfaces/user.model';
import { SaveUser } from '@app/users/interfaces/saveUser.interface';
import { TokenRefresh } from '@app/account/interfaces/refreshTokenRequest.interface';
import { TokenService } from './token.service';
import { map, tap } from 'rxjs/operators';

enum ep {
  Login = '/auth/login',
  Logout = '/auth/logout',
  Register = '/auth/register',
  Reset = '/auth/reset'
}

@Injectable()
export class AuthenticationService {
  redirectUrl: string;

  constructor(
    private http: HttpClient,
    private store: Store,
    private tokenService: TokenService
  ) {}

  register$(user: SaveUser): Observable<Response> {
    return this.http.post<Response>(environment.apiUrl + ep.Register, user);
  }

  verify$(userId: string, token: string): Observable<Response> {
    return this.http.post<Response>(`${environment.apiUrl}/auth/verify`, {
      userId,
      token
    });
  }

  login$(authCredentials: AuthCredentials): Observable<AuthorizationResponse> {
    return this.http
      .post<AuthorizationResponse>(
        environment.apiUrl + ep.Login,
        authCredentials
      )
      .pipe(
        map(response => {
          // Let's not allow the tokens to be viewed in redux dev tools
          const { accessToken, refreshToken, ...rest } = response;
          this.tokenService.saveTokens(accessToken, refreshToken);
          return rest;
        })
      );
  }

  refresh$(tokens: TokenRefresh): Observable<TokenRefresh> {
    return this.http
      .post<TokenRefresh>(`${environment.apiUrl}/auth`, tokens)
      .pipe(
        map(response => {
          this.tokenService.saveTokens(
            response.accessToken,
            response.refreshToken
          );
          return response;
        })
      );
  }

  forgotPassword$(email: string): Observable<Response> {
    return this.http.post<Response>(
      `${environment.apiUrl}/auth/forgot_password`,
      {
        email
      }
    );
  }

  resetPasswordFromEmail$(
    userId: string,
    token: string,
    password: string
  ): Observable<Response> {
    return this.http.post<Response>(
      `${environment.apiUrl}/auth/password_reset`,
      {
        token,
        password,
        userId
      }
    );
  }

  changePassword$(
    id: string,
    currentPassword: string,
    password: string
  ): Observable<Response> {
    return this.http.post<Response>(
      `${environment.apiUrl}/auth/password_change`,
      {
        id,
        currentPassword,
        password
      }
    );
  }

  updateUser$(user: ModifiedUser): Observable<User> {
    return this.http.put<User>(`${environment.apiUrl}/users/${user.id}`, user);
  }

  logOut(redirectUrl: string = null) {
    this.redirectUrl = redirectUrl;

    this.store.dispatch(LogoutRequested());
  }
}
