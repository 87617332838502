import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRoles } from '../constants/userRoles.constants';
import { selectUserRole } from '../store/auth/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class DonorGuard implements CanActivate {
  private readonly roles = [
    UserRoles.Admin,
    UserRoles.FuneralHomeEmployee,
    UserRoles.OpoEmployee,
    UserRoles.ReferralUser
  ];

  constructor(private store: Store, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.select(selectUserRole).pipe(
      map(role => {
        // Check if user's role is included in allowed roles
        const hasAccess = this.roles.indexOf(role) !== -1 && this.roles.indexOf(role) !== 3;
        // If not, navigate to plans (a safe fallback)
        if (!hasAccess) this.router.navigate(['/referral/form']);
        // Return observable
        return hasAccess;
      })
    );
  }
}
