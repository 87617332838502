import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { SaveUserProfile } from '../interfaces/saveUserProfile.interface';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(private http: HttpClient) {}

  updateUserProfile$(
    userId: string,
    userProfile: SaveUserProfile
  ): Observable<void> {
    return this.http.put<void>(
      `${environment.apiUrl}/users/${userId}/profile`,
      userProfile
    );
  }
}
