import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { DonorGuard } from '../guards/donor.guard';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () =>
      import('../../account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'donors',
    canActivate: [AuthGuard, DonorGuard],
    loadChildren: () =>
      import('../../donors/donors.module').then(m => m.DonorsModule)
  },
  {
    path: 'referral',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../../referral/referral.module').then(m => m.ReferralModule)
  },
  {
    path: 'users',
    loadChildren: () =>
      import('../../users/users.module').then(m => m.UserModule)
  },
  {
    path: '**',
    redirectTo: '/donors'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {}
