/* tslint:disable no-any */

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
  HttpHeaders
} from '@angular/common/http';
import { TokenService } from '../services/token.service';
import { AuthenticationService } from '../services';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: TokenService,
    private authenticationService: AuthenticationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const accessToken = this.tokenService.getAccessToken();

    const headers = new HttpHeaders({
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {})
    });

    return next.handle(request.clone({ headers }));
  }
}

export const authorizationInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthorizationInterceptor,
    multi: true
  }
];
