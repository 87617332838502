import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { ToastService } from '../services/toast.service';
import { TokenService } from '../services/token.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: TokenService,
    private authenticationService: AuthenticationService,
    private toasterService: ToastService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          if (this.tokenService.hasTokenExpired()) {
            return this.authenticationService
              .refresh$({
                accessToken: this.tokenService.getAccessToken(),
                refreshToken: this.tokenService.getRefreshToken()
              })
              .pipe(
                switchMap(() => {
                  const accessToken = this.tokenService.getAccessToken();
                  return next.handle(
                    request.clone({
                      setHeaders: { Authorization: `Bearer ${accessToken}` }
                    })
                  );
                })
              );
          }
          this.authenticationService.logOut();
        } else {
          this.toasterService.showErrorToast(err.statusText);
          return throwError(err);
        }
      })
    );
  }
}
