import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'timeAgo'})
export class TimeAgoPipe implements PipeTransform {
  transform(value: string) {
    const utcTime = new Date(value);
    const timeAgo = moment(utcTime).fromNow();
    return timeAgo;
  }
}