import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationService } from './services';
import { AppRoutingModule } from './routing/app.routing';
import { AppStoreModule } from './store/app.store';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { ToastService } from './services/toast.service';
import { IdleModalComponent } from './idle-modal/idle-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { TokenService } from './services/token.service';
import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '@env/environment';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [IdleModalComponent],
  imports: [
    SharedModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AppStoreModule,
    BrowserAnimationsModule
  ],
  providers: [
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    TokenService,
    CookieService,
    ToastService
  ],
  exports: [AppRoutingModule, AppStoreModule, IdleModalComponent],
  entryComponents: [IdleModalComponent]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    core: CoreModule
  ) {
    if (core) {
      throw new Error('CoreModule already instantiated!');
    }
  }
}
