import { Action, createReducer, on } from '@ngrx/store';
import { DonorDetails } from '../interfaces/donorDetails.interface';
import * as DonorActions from './donor.actions';


export const donorFeatureKey = "donor";

export interface DonorState {
  selectedDonorDetails: DonorDetails;
}

export const initialState: DonorState = {
  selectedDonorDetails: null
}

const donorReducer = createReducer(
  initialState,
  on(DonorActions.UpdateSelectedDonor, (state, action) => ({
    ...state,
    selectedDonorDetails: action.donorDetails
  })),
  on(DonorActions.UpdateDonorTimeline, (state, action) => ({
    ...state,
    selectedDonorDetails: {
      ...state.selectedDonorDetails,
      timelineDetails: action.timeline
    }
  }))
);

export function reducer(state: DonorState | undefined, action: Action) {
  return donorReducer(state, action);
}
